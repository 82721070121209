import React from "react"
import presets from "../utils/presets"

// import { rhythm } from "../utils/typography"

const Container = ({
  children,
  className = ``,
  hasSideBar = true,
  overrideCSS = {},
}) => (
  <div
    css={{
      // maxWidth: hasSideBar
      //   ? rhythm(presets.maxWidthWithSidebar)
      //   : rhythm(presets.maxWidth),
      maxWidth: '100%',
      margin: `0 auto`,
      padding: 24,
      // paddingBottom: rhythm(3.5),
      position: `relative`,
      [presets.Tablet]: {
        // paddingBottom: rhythm(1.5),
      },
      ...overrideCSS,
    }}
    className={className}
  >
    {children}
  </div>
)

export default Container
