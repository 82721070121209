import React from 'react'
import { Form, Input, notification } from 'antd'
import Button from "../button"
// import presets, { colors } from "../../utils/presets"
// import { rhythm, scale } from "../../utils/typography"
// import { vP, vPHd, vPVHd, vPVVHd } from "../../components/gutters"
import $ from "jquery"
const { TextArea } = Input

notification.config({
  top: 100,
});

class TrialForm extends React.Component {

  state = {
    code: '',
    codeStatus: ''
  }

  componentDidMount() {
    this.createCode()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!values.code || values.code.toUpperCase() !== this.state.code) {
          this.setState({ codeStatus: 'error' });
          if (values.code) {
            this.createCode();
            this.props.form.setFieldsValue({ code: '' });
          }
          this.codeInput.focus();
          return;
        }
        this.createCode();
        this.props.form.setFieldsValue({ code: '' });
        this.setState({ codeStatus: '' });
        delete values.code;
        console.log('Received values of form: ', values);
        // const _this = this;
        // return;
        let url = 'https://eccqa.weadmin.com';
        $.ajax({
          type: "post",
          url: `${url}/reguserinfo/add`,
          dataType: "json",
          // contentType: 'application/json',
          // data: JSON.stringify(values),
          data: values,
          success : function(result){
            // console.log(result);
            if (result && result.code === 0) {
              notification.success({
                message: '申请成功',
                description: '感谢您提交申请，30分钟内客服经理会与您联系！',
              });
            }
          }, error : function(err){
            // notification.warning({
            //   message: '申请失败',
            //   description: '请稍后再试！',
            // });
          }
      });
      }
    });
  }

  createCode = () => {
    var code = '';
    var codeLength = 4;
    var random = new Array(0,1,2,3,4,5,6,7,8,9,'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R', 'S','T','U','V','W','X','Y','Z');
    for(var i = 0; i < codeLength; i++){
      var index = Math.floor(Math.random()*36);
      code += random[index];
    }
    // return code;
    this.setState({ code });
  }

  validatePhone = (rule, value, callback) => {
    if(!(/^1[34578]\d{9}$/.test(value)) && !/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)){
      callback("输入的联系电话无效！");
    } else {
      callback();
    }
    // 固定电话
    // if(!/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)){
    //   callback("输入的联系电话无效！");
    // } else {
    //   callback();
    // }
  }

  validateEmail = (rule, value, callback) => {
    const emails = ['@qq', '@163', '@139', '@gmail', '@sohu']
    let _flag = false;
    for (var i = 0; i < emails.length; i++) {
      if (value.indexOf(emails[i] + '.') > -1) {
        _flag = true;
        break;
      }
    }
    if (_flag) {
      callback('输入的企业邮箱无效！');
    } else {
      callback();
    }
  }

  render() {
    const { code, codeStatus } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 12,
          offset: 4,
        },
      },
    };
    return (
      <>
        <div className="trial-form" css={styles.formDiv}>
          <Form>
            <Form.Item
              {...formItemLayout}
              label="公司名称"
            >
              {getFieldDecorator('CompanyName', {
                initialValue: '',
                rules: [{
                  required: true, message: '请输入您的公司名称！',
                }]
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="联系人"
            >
              {getFieldDecorator('ContactPerson', {
                initialValue: '',
                rules: [{
                  required: true, message: '请输入联系人！',
                }]
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="联系电话"
            >
              {getFieldDecorator('PhoneNumber', {
                validateFirst: true,
                rules: [{
                  required: true, message: '请输入您的联系电话！',
                }, {
                  validator: this.validatePhone
                }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="企业邮箱"
            >
              {getFieldDecorator('Email', {
                validateFirst: true,
                rules: [
                  { required: true, message: '请输入您的企业邮箱！' },
                  { type: 'email', message: '输入的企业邮箱无效！' },
                  // { validator: this.validateEmail }
                ],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="需求描述"
            >
              {getFieldDecorator('DemandDes', {
                initialValue: '',
                rules: [{
                  required: true, message: '请输入需求描述！',
                }]
              })(
                <TextArea rows={4} />
              )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout} validateStatus={codeStatus}>
              {getFieldDecorator('code', {
                initialValue: '',
              })(
                <Input ref={input => this.codeInput = input} css={{ width: 100 }} />
              )}
              <a css={styles.code} onClick={this.createCode}>{code}</a>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button small to="javascript:;" tag="href" onClick={this.handleSubmit} overrideCSS={styles.button} style={{textDecoration: 'none'}}>
                  提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    )
  }
}

const styles = {
  formDiv: {
    maxWidth: 600
  },
  button: {
    marginBottom: `1rem`,
    // minWidth: 100,
    width: '100%',
    justifyContent: 'center'
  },
  code: {
    fontFamily: 'Arial',
    fontStyle: 'italic',
    fontWeight: 'bold',
    border: '1px solid #ddd',
    letterSpacing: '3px',
    color: 'blue',
    padding: '5px 12px',
    marginLeft: 15,
    fontSize: 18,
    minWidth: 100,
    display: 'inline-block',
    lineHeight: '20px',
    textAlign: 'center',
    "&:hover": {
      color: 'blue',
      opacity: 0.8
    }
  }
}

const WrappedTrialForm = Form.create()(TrialForm);

export default WrappedTrialForm
