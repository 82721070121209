import DocsSidebar from "../../data/sidebars/document-links.yaml"
import StarterSidebar from "../../data/sidebars/starter-links.yaml"
import ProductSidebar from "../../data/sidebars/product-links.yaml"
import DownloadSidebar from "../../data/sidebars/download-links.yaml"
import JCGLSidebar from "../../data/sidebars/ecc/ecc-jcgl-links.yaml"
import GZGJSidebar from "../../data/sidebars/ecc/ecc-gzgj-links.yaml"
import LSQXSidebar from "../../data/sidebars/ecc/ecc-lsqx-links.yaml"
import BBBGSidebar from "../../data/sidebars/ecc/ecc-bbbg-links.yaml"
import YHGLSidebar from "../../data/sidebars/ecc/ecc-yhgl-links.yaml"
import MORESidebar from "../../data/sidebars/ecc/ecc-more-links.yaml"
import TechnologySidebar from "../../data/sidebars/technology-links.yaml"
import CaseSidebar from "../../data/sidebars/case-links.yaml"
import DownloadUpdate from "../../data/download/update-links.yaml"
import AboutSidebar from "../../data/sidebars/about-links.yaml"
import FaqSidebar from "../../data/sidebars/faq-links.yaml"
import QsSidebar from "../../data/sidebars/qs-links.yaml"


const createHash = link => {
  let index = -1
  if (link) index = link.indexOf(`#`)
  return index >= 0 ? link.substr(index + 1) : false
}

const extenditemList = itemList => {
  itemList.forEach(section => {
    if (section.items) extendItem(section.items, section.title)
  })
  return itemList
}

const extendItem = (items, parentTitle) => {
  items.forEach(item => {
    item.hash = createHash(item.link)
    item.parentTitle = parentTitle
    if (item.items) extendItem(item.items, item.title)
  })
}

const itemListDocs = extenditemList(DocsSidebar).map(item => {
  return { ...item, key: `docs` }
})

const itemListStarter = extenditemList(StarterSidebar).map(item => {
  return { ...item, key: `starter` }
})

const itemListProd = extenditemList(ProductSidebar).map(item => {
  return { ...item, key: `prod` }
})

const itemListDownload = extenditemList(DownloadSidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListJCGL = extenditemList(JCGLSidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListGZGJ = extenditemList(GZGJSidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListLSQX = extenditemList(LSQXSidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListBBBG = extenditemList(BBBGSidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListYHGL = extenditemList(YHGLSidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListMORE = extenditemList(MORESidebar).map(item => {
  return { ...item, key: `download` }
})

const itemListTechnology = extenditemList(TechnologySidebar).map(item => {
  return { ...item, key: `technology` }
})

const itemListCase = extenditemList(CaseSidebar).map(item => {
  return { ...item, key: `case` }
})

const itemListUpdate = extenditemList(DownloadUpdate).map(item => {
  return { ...item, key: `update` }
})

const itemListAbout = extenditemList(AboutSidebar).map(item => {
  return { ...item, key: `about` }
})
const itemListFaq = extenditemList(FaqSidebar).map(item => {
  return { ...item, key: `faq` }
})
const itemListQs = extenditemList(QsSidebar).map(item => {
  return { ...item, key: `qs` }
})

export {
  itemListStarter,
  itemListDocs,
  itemListProd,
  itemListDownload,
  itemListJCGL,
  itemListGZGJ,
  itemListLSQX,
  itemListBBBG,
  itemListYHGL,
  itemListMORE,
  itemListTechnology,
  itemListCase,
  itemListUpdate,
  itemListAbout,
  itemListFaq,
  itemListQs,
}
